import { deepCopy, isArray, isFunction, isString, toTypeString } from "@intlify/shared";
import { createLogger } from "#nuxt-i18n/logger";
const cacheMessages = /* @__PURE__ */ new Map();
export async function loadVueI18nOptions(vueI18nConfigs, nuxt) {
  const vueI18nOptions = { messages: {} };
  for (const configFile of vueI18nConfigs) {
    const { default: resolver } = await configFile();
    const resolved = isFunction(resolver) ? await nuxt.runWithContext(() => resolver()) : resolver;
    deepCopy(resolved, vueI18nOptions);
  }
  return vueI18nOptions;
}
export function makeFallbackLocaleCodes(fallback, locales) {
  if (fallback === false) return [];
  if (isArray(fallback)) return fallback;
  let fallbackLocales = [];
  if (isString(fallback)) {
    if (locales.every((locale) => locale !== fallback)) {
      fallbackLocales.push(fallback);
    }
    return fallbackLocales;
  }
  const targets = [...locales, "default"];
  for (const locale of targets) {
    if (locale in fallback == false) continue;
    fallbackLocales = [...fallbackLocales, ...fallback[locale].filter(Boolean)];
  }
  return fallbackLocales;
}
export async function loadInitialMessages(messages, localeLoaders, options, nuxt) {
  const { defaultLocale, initialLocale, localeCodes, fallbackLocale, lazy } = options;
  if (lazy && fallbackLocale) {
    const fallbackLocales = makeFallbackLocaleCodes(fallbackLocale, [defaultLocale, initialLocale]);
    await Promise.all(fallbackLocales.map((locale) => loadAndSetLocaleMessages(locale, localeLoaders, messages, nuxt)));
  }
  const locales = lazy ? [...(/* @__PURE__ */ new Set()).add(defaultLocale).add(initialLocale)] : localeCodes;
  await Promise.all(locales.map((locale) => loadAndSetLocaleMessages(locale, localeLoaders, messages, nuxt)));
  return messages;
}
const isModule = (val) => toTypeString(val) === "[object Module]";
async function loadMessage(locale, { key, load }, nuxt) {
  const logger = /* @__PURE__ */ createLogger("loadMessage");
  let message = null;
  try {
    __DEBUG__ && logger.log({ locale });
    const getter = await load().then((x) => isModule(x) ? x.default : x);
    if (isFunction(getter)) {
      message = await nuxt.runWithContext(() => getter(locale));
      __DEBUG__ && logger.log("dynamic load", logger.level >= 999 ? message : "");
    } else {
      message = getter;
      if (message != null && cacheMessages && !import.meta.dev) {
        cacheMessages.set(key, message);
      }
      __DEBUG__ && logger.log("loaded", logger.level >= 999 ? message : "");
    }
  } catch (e) {
    console.error("Failed locale loading: " + e.message);
  }
  return message;
}
export async function loadLocale(locale, localeLoaders, setter, nuxt) {
  const logger = /* @__PURE__ */ createLogger("loadLocale");
  const loaders = localeLoaders[locale];
  if (loaders == null) {
    __DEBUG__ && logger.warn("Could not find locale file messages for locale code: " + locale);
    return;
  }
  const targetMessage = {};
  for (const loader of loaders) {
    let message = null;
    if (cacheMessages && cacheMessages.has(loader.key) && loader.cache) {
      __DEBUG__ && logger.log(loader.key + " is already loaded");
      message = cacheMessages.get(loader.key);
    } else {
      __TEST__ && !loader.cache && logger.log(loader.key + " bypassing cache!");
      __DEBUG__ && logger.log(loader.key + " is loading ...");
      message = await nuxt.runWithContext(() => loadMessage(locale, loader, nuxt));
    }
    if (message != null) {
      deepCopy(message, targetMessage);
    }
  }
  setter(locale, targetMessage);
}
export async function loadAndSetLocaleMessages(locale, localeLoaders, messages, nuxt) {
  const setter = (locale2, message) => {
    const base = messages[locale2] || {};
    deepCopy(message, base);
    messages[locale2] = base;
  };
  await loadLocale(locale, localeLoaders, setter, nuxt);
}
