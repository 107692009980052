import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/tmp/build_98043470/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/tmp/build_98043470/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/tmp/build_98043470/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/tmp/build_98043470/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_daf8JLQYgNg5Oio6i3szlN9gBfCuQpCD32G5_g4EIgQ from "/tmp/build_98043470/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/tmp/build_98043470/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/tmp/build_98043470/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/tmp/build_98043470/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/tmp/build_98043470/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/tmp/build_98043470/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/tmp/build_98043470/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/tmp/build_98043470/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/tmp/build_98043470/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/tmp/build_98043470/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/tmp/build_98043470/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg from "/tmp/build_98043470/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk from "/tmp/build_98043470/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/tmp/build_98043470/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import _01_clog_lkiJUuUw6mp8LO5qAvKvVZ20LxEQNjo_vGtd9EQjGxA from "/tmp/build_98043470/plugins/01.clog.js";
import _02_syncSupabaseUser_k7J2gyxqS_1xTjGfod_1Yb8GHHOJWeMDCBbZ35G2JNI from "/tmp/build_98043470/plugins/02.syncSupabaseUser.js";
import _03_metric_xR1isqWkW0oXXR_P9ABQERqlCXZyC1X6yLNJ4d8j9qw from "/tmp/build_98043470/plugins/03.metric.js";
import apexcharts_client_zWvncYMwbs_vo0eoAq7xPwNeVIXR2yTKM8UI1Ydbt8g from "/tmp/build_98043470/plugins/apexcharts.client.js";
import appCreated_client_22Diku_wVYUEGlZtDmxKlvxz_AElPF366Gb77tb7DYQ from "/tmp/build_98043470/plugins/appCreated.client.js";
import appMounted_k8YMy9c2LKC43ZwQxQoMp12JdLWTzth1oVZcnTxY_rI from "/tmp/build_98043470/plugins/appMounted.js";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/tmp/build_98043470/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  supabase_client_daf8JLQYgNg5Oio6i3szlN9gBfCuQpCD32G5_g4EIgQ,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg,
  plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  _01_clog_lkiJUuUw6mp8LO5qAvKvVZ20LxEQNjo_vGtd9EQjGxA,
  _02_syncSupabaseUser_k7J2gyxqS_1xTjGfod_1Yb8GHHOJWeMDCBbZ35G2JNI,
  _03_metric_xR1isqWkW0oXXR_P9ABQERqlCXZyC1X6yLNJ4d8j9qw,
  apexcharts_client_zWvncYMwbs_vo0eoAq7xPwNeVIXR2yTKM8UI1Ydbt8g,
  appCreated_client_22Diku_wVYUEGlZtDmxKlvxz_AElPF366Gb77tb7DYQ,
  appMounted_k8YMy9c2LKC43ZwQxQoMp12JdLWTzth1oVZcnTxY_rI,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]