export default defineNuxtPlugin((nuxtApp) => {
  const supabase = useSupabaseClient()
  const profileStore = useProfileStore()
  const { $log } = useNuxtApp()
  
  const handleAuthStateChange = (event, session) => {
    $log('plugins:syncSupabaseUser:onAuthStateChange:', event)
    
    // Use Promise.resolve().then() to handle async operations outside the event handler
    Promise.resolve().then(() => {
      switch (event) {
        case 'INITIAL_SESSION':
          if (session?.user) {
            profileStore.fetchSupabaseUser(supabase)
              .then(() => Promise.all([
                profileStore.fetchCurrentUser(supabase),
                profileStore.fetchProfiles(supabase),
                profileStore.fetchShareProOrigin(supabase)
              ]))
              .then(() => {
                $log('plugins:syncSupabaseUser:profile data loaded')
              })
              .catch(error => {
                $log('plugins:syncSupabaseUser:error:', error)
              })
          } else {
            profileStore.clearUser()
          }
          break
        case 'SIGNED_IN':
          profileStore.fetchSupabaseUser(supabase)
            .then(() => Promise.all([
              profileStore.fetchCurrentUser(supabase),
              profileStore.fetchProfiles(supabase),
              profileStore.fetchShareProOrigin(supabase)
            ]))
            .then(() => {
              $log('plugins:syncSupabaseUser:profile data loaded')
            })
            .catch(error => {
              $log('plugins:syncSupabaseUser:error:', error)
            })
          break
        case 'SIGNED_OUT':
          profileStore.clearUser()
          break
        case 'USER_UPDATED':
          profileStore.fetchSupabaseUser(supabase)
            .then(() => Promise.all([
              profileStore.fetchCurrentUser(supabase),
              profileStore.fetchProfiles(supabase),
              profileStore.fetchShareProOrigin(supabase)
            ]))
            .then(() => {
              $log('plugins:syncSupabaseUser:profile data loaded')
            })
            .catch(error => {
              $log('plugins:syncSupabaseUser:error:', error)
            })
          break
        case 'USER_DELETED':
          profileStore.clearUser()
          break
      }
    })
  }

  // Set up auth listener
  const { data: { subscription } } = supabase.auth.onAuthStateChange(handleAuthStateChange)

  // Clean up subscription when app is destroyed
  nuxtApp.hook('app:destroyed', () => {
    subscription?.unsubscribe()
  })
})
