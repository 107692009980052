
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index5GpHm4brffqjVmzLoFOmJ3W9oyZGv2xbnh2JxoDaT2AMeta } from "/tmp/build_98043470/pages/index.vue?macro=true";
import { default as confirm2juaKBdCWts0iBeYOUqr95C7yDqpJGpqIXK_45zP8p7EEMeta } from "/tmp/build_98043470/pages/confirm.vue?macro=true";
import { default as supportr2ZjosC60NOYg2MQoq_1IZCVCiGarUW0ksXDC_ZOz_wMeta } from "/tmp/build_98043470/pages/support.vue?macro=true";
import { default as indexuFiIsWqu9C3MerttuNl1nwa40Xtfi0vX6B1O7q0QRpMMeta } from "/tmp/build_98043470/pages/map/index.vue?macro=true";
import { default as index5sbohcYOcvBvOfiFI0q1QI_45J6mGuyayihYQaaeWfahMMeta } from "/tmp/build_98043470/pages/zemma/index.vue?macro=true";
import { default as teamNxm6I1QRTYei6czOPmqI5MToZlmN5y8JowKCUZzUW3sMeta } from "/tmp/build_98043470/pages/company/team.vue?macro=true";
import { default as imprintENLODfjDpAzOMLLk44AoOEgYPivYDNhrNIdSXkDYQvgMeta } from "/tmp/build_98043470/pages/legal/imprint.vue?macro=true";
import { default as index43s_456TOCTk1GLj_45BS8I2IrhPRFFf2AsidMgEvl9eaYEMeta } from "/tmp/build_98043470/pages/map/feed/index.vue?macro=true";
import { default as indexLD_45waZKXlEzs_r7rKr17X0o2904rh_O05yig36s9lz0Meta } from "/tmp/build_98043470/pages/dashboard/index.vue?macro=true";
import { default as indexuQIBu67AuI6zBEyN5AoYFvZzI7R2dBJm9NR5OtbFguoMeta } from "/tmp/build_98043470/pages/auth/sign-in/index.vue?macro=true";
import { default as index2aJvZX9k9l25mJlvnFaiKiQO5OaXNjtbaEPG1hr95iUMeta } from "/tmp/build_98043470/pages/auth/register/index.vue?macro=true";
import { default as error_45test_45page_45quoR2affGHsew_Ik6bDHzIrEx2eVVGaNNFmOLYjm4Wa_XEMeta } from "/tmp/build_98043470/pages/error-test-page-quo.vue?macro=true";
import { default as cancelsveiDLeVYMUeqlbBPCbWIep0_DOhwPGnN_4sDQYoCVUMeta } from "/tmp/build_98043470/pages/auth/checkout/cancel.vue?macro=true";
import { default as privacy_45policy93UFq7HgvHprds51W4Z5Vn5Bqzd9XVtlBUDCB3FPHzgMeta } from "/tmp/build_98043470/pages/legal/privacy-policy.vue?macro=true";
import { default as successjka42EHI9xcMbNHTykMKfU_45j__458I1kF_e_45_aBlCkMH4Meta } from "/tmp/build_98043470/pages/auth/checkout/success.vue?macro=true";
import { default as share_45proUDU5vz32Rma7jpRH482FKKF60zuKtzSh_45Hon8dfDJWQMeta } from "/tmp/build_98043470/pages/auth/invite/share-pro.vue?macro=true";
import { default as indexeJw7K80lyKJ_kDRQ16ld6KIHjIaybhqKp3k2cCbCvb4Meta } from "/tmp/build_98043470/pages/dashboard/users/index.vue?macro=true";
import { default as _91_91id_93_93bUiMrFu3zEtth7dqiAFC3GYswifloVQeB3vX5pCwEo8Meta } from "/tmp/build_98043470/pages/dashboard/lists/[[id]].vue?macro=true";
import { default as _91_91id_93_93P7R_45tTpsO8lXvGT9PnX9auDD11HEEMJ94MQqsA4WEnUMeta } from "/tmp/build_98043470/pages/dashboard/media/[[id]].vue?macro=true";
import { default as terms_45of_45serviceo_EtOlhISfeO60PysjM97DoH6MKWqoJA38OOQGw2DmcMeta } from "/tmp/build_98043470/pages/legal/terms-of-service.vue?macro=true";
import { default as _91_91id_93_93ZHf41OynNWtFr53sBZglF6C8snS2clyvHhroQtfK1D0Meta } from "/tmp/build_98043470/pages/dashboard/guides/[[id]].vue?macro=true";
import { default as _91_91id_93_93IY_45SzQ_45flbvD9maiRJQRha5ljEy2mTYtoAyy7Lz_458fYMeta } from "/tmp/build_98043470/pages/dashboard/places/[[id]].vue?macro=true";
import { default as forward_45urlqyfaPFJ2nyiecTaf2qJFAZx_hmLRKcS9aySMC2maxhAMeta } from "/tmp/build_98043470/pages/auth/register/forward-url.vue?macro=true";
import { default as confirm_45email6uqBZkm_45zyHlY_e1xj8ZoH94yUFwkk5AfEH6Oh7uflsMeta } from "/tmp/build_98043470/pages/auth/register/confirm-email.vue?macro=true";
import { default as reset_45passwordhJ9JO321jqp0pZqPc807U1ND8gLkwQSzorrAgOeWGYYMeta } from "/tmp/build_98043470/pages/auth/sign-in/reset-password.vue?macro=true";
import { default as _91_91slug_93_93InScr5HuBUobOKMqng3X6TrAKw0kOZfXJJ92lQaloEwMeta } from "/tmp/build_98043470/pages/map/place/[nanoId]/[[slug]].vue?macro=true";
import { default as choose_45product3SOc2WQbxsa7t0t3iTIEQuCrilSBSd2THiDe3FjI6GYMeta } from "/tmp/build_98043470/pages/auth/checkout/choose-product.vue?macro=true";
import { default as create_45profileCtZGYQ6MprjLST6WCKOCrHqXg84_VGvF2xYDTpfEw2oMeta } from "/tmp/build_98043470/pages/auth/register/create-profile.vue?macro=true";
import { default as _91_91id_93_93lKgdP79N1C_45GIJ3qzJhUfeRsZ7IPq9poCKB56USWGXoMeta } from "/tmp/build_98043470/pages/dashboard/helper/tags/[[id]].vue?macro=true";
import { default as _91_91slug_93_93I5HpRbcE3XweAkvqOfBnmbrnX2TAA2p4c4gFbKBDimMMeta } from "/tmp/build_98043470/pages/map/list/[[nanoId]]/[[slug]].vue?macro=true";
import { default as success_45quo_45pro7u1w7FaiWrM160kGpVpYe5om6qdajOwSAd3EC7BXqXAMeta } from "/tmp/build_98043470/pages/auth/checkout/success-quo-pro.vue?macro=true";
import { default as share_45pro_45successwI5n7Mu_45qAgKTK6BvDNYEyK1N_6Mv3iTyxYwQXXyE9cMeta } from "/tmp/build_98043470/pages/auth/invite/share-pro-success.vue?macro=true";
import { default as send_45password_45linkOUB89OPdZX1H9rIIM5Ma0wA6Bs9I9BBPGIEdbTSY5PwMeta } from "/tmp/build_98043470/pages/auth/sign-in/send-password-link.vue?macro=true";
import { default as indexMuZmlbyqrBjLzkWW0XBFe6EeOYWShmFgwtUfJtHArEEMeta } from "/tmp/build_98043470/pages/dashboard/helper/countries/index.vue?macro=true";
import { default as _91highlightUrlName_93TVPIxojZrOdreAsqvAJx9DA03xouUtRt4jSC254g9wsMeta } from "/tmp/build_98043470/pages/map/highlight/[highlightUrlName].vue?macro=true";
import { default as indexkUxs7XHHYgTZHDAJTcyXWk_45hgMFgIhyEgOJkHC0Ge78Meta } from "/tmp/build_98043470/pages/map/guide/[nanoId]/[title]/[[key]]/index.vue?macro=true";
export default [
  {
    name: "index___en",
    path: "/",
    meta: index5GpHm4brffqjVmzLoFOmJ3W9oyZGv2xbnh2JxoDaT2AMeta || {},
    component: () => import("/tmp/build_98043470/pages/index.vue")
  },
  {
    name: "confirm___en",
    path: "/confirm",
    component: () => import("/tmp/build_98043470/pages/confirm.vue")
  },
  {
    name: "support___en",
    path: "/support",
    component: () => import("/tmp/build_98043470/pages/support.vue")
  },
  {
    name: "map___en",
    path: "/map",
    meta: indexuFiIsWqu9C3MerttuNl1nwa40Xtfi0vX6B1O7q0QRpMMeta || {},
    component: () => import("/tmp/build_98043470/pages/map/index.vue")
  },
  {
    name: "zemma___en",
    path: "/zemma",
    component: () => import("/tmp/build_98043470/pages/zemma/index.vue")
  },
  {
    name: "company-team___en",
    path: "/company/team",
    component: () => import("/tmp/build_98043470/pages/company/team.vue")
  },
  {
    name: "legal-imprint___en",
    path: "/legal/imprint",
    component: () => import("/tmp/build_98043470/pages/legal/imprint.vue")
  },
  {
    name: "map-feed___en",
    path: "/map/feed",
    meta: index43s_456TOCTk1GLj_45BS8I2IrhPRFFf2AsidMgEvl9eaYEMeta || {},
    component: () => import("/tmp/build_98043470/pages/map/feed/index.vue")
  },
  {
    name: "dashboard___en",
    path: "/dashboard",
    meta: indexLD_45waZKXlEzs_r7rKr17X0o2904rh_O05yig36s9lz0Meta || {},
    component: () => import("/tmp/build_98043470/pages/dashboard/index.vue")
  },
  {
    name: "auth-sign-in___en",
    path: "/auth/sign-in",
    meta: indexuQIBu67AuI6zBEyN5AoYFvZzI7R2dBJm9NR5OtbFguoMeta || {},
    component: () => import("/tmp/build_98043470/pages/auth/sign-in/index.vue")
  },
  {
    name: "auth-register___en",
    path: "/auth/register",
    meta: index2aJvZX9k9l25mJlvnFaiKiQO5OaXNjtbaEPG1hr95iUMeta || {},
    component: () => import("/tmp/build_98043470/pages/auth/register/index.vue")
  },
  {
    name: "error-test-page-quo___en",
    path: "/error-test-page-quo",
    component: () => import("/tmp/build_98043470/pages/error-test-page-quo.vue")
  },
  {
    name: "auth-checkout-cancel___en",
    path: "/auth/checkout/cancel",
    meta: cancelsveiDLeVYMUeqlbBPCbWIep0_DOhwPGnN_4sDQYoCVUMeta || {},
    component: () => import("/tmp/build_98043470/pages/auth/checkout/cancel.vue")
  },
  {
    name: "legal-privacy-policy___en",
    path: "/legal/privacy-policy",
    component: () => import("/tmp/build_98043470/pages/legal/privacy-policy.vue")
  },
  {
    name: "auth-checkout-success___en",
    path: "/auth/checkout/success",
    meta: successjka42EHI9xcMbNHTykMKfU_45j__458I1kF_e_45_aBlCkMH4Meta || {},
    component: () => import("/tmp/build_98043470/pages/auth/checkout/success.vue")
  },
  {
    name: "auth-invite-share-pro___en",
    path: "/auth/invite/share-pro",
    meta: share_45proUDU5vz32Rma7jpRH482FKKF60zuKtzSh_45Hon8dfDJWQMeta || {},
    component: () => import("/tmp/build_98043470/pages/auth/invite/share-pro.vue")
  },
  {
    name: "dashboard-users___en",
    path: "/dashboard/users",
    meta: indexeJw7K80lyKJ_kDRQ16ld6KIHjIaybhqKp3k2cCbCvb4Meta || {},
    component: () => import("/tmp/build_98043470/pages/dashboard/users/index.vue")
  },
  {
    name: "dashboard-lists-id___en",
    path: "/dashboard/lists/:id?",
    meta: _91_91id_93_93bUiMrFu3zEtth7dqiAFC3GYswifloVQeB3vX5pCwEo8Meta || {},
    component: () => import("/tmp/build_98043470/pages/dashboard/lists/[[id]].vue")
  },
  {
    name: "dashboard-media-id___en",
    path: "/dashboard/media/:id?",
    meta: _91_91id_93_93P7R_45tTpsO8lXvGT9PnX9auDD11HEEMJ94MQqsA4WEnUMeta || {},
    component: () => import("/tmp/build_98043470/pages/dashboard/media/[[id]].vue")
  },
  {
    name: "legal-terms-of-service___en",
    path: "/legal/terms-of-service",
    component: () => import("/tmp/build_98043470/pages/legal/terms-of-service.vue")
  },
  {
    name: "dashboard-guides-id___en",
    path: "/dashboard/guides/:id?",
    meta: _91_91id_93_93ZHf41OynNWtFr53sBZglF6C8snS2clyvHhroQtfK1D0Meta || {},
    component: () => import("/tmp/build_98043470/pages/dashboard/guides/[[id]].vue")
  },
  {
    name: "dashboard-places-id___en",
    path: "/dashboard/places/:id?",
    meta: _91_91id_93_93IY_45SzQ_45flbvD9maiRJQRha5ljEy2mTYtoAyy7Lz_458fYMeta || {},
    component: () => import("/tmp/build_98043470/pages/dashboard/places/[[id]].vue")
  },
  {
    name: "auth-register-forward-url___en",
    path: "/auth/register/forward-url",
    meta: forward_45urlqyfaPFJ2nyiecTaf2qJFAZx_hmLRKcS9aySMC2maxhAMeta || {},
    component: () => import("/tmp/build_98043470/pages/auth/register/forward-url.vue")
  },
  {
    name: "auth-register-confirm-email___en",
    path: "/auth/register/confirm-email",
    meta: confirm_45email6uqBZkm_45zyHlY_e1xj8ZoH94yUFwkk5AfEH6Oh7uflsMeta || {},
    component: () => import("/tmp/build_98043470/pages/auth/register/confirm-email.vue")
  },
  {
    name: "auth-sign-in-reset-password___en",
    path: "/auth/sign-in/reset-password",
    meta: reset_45passwordhJ9JO321jqp0pZqPc807U1ND8gLkwQSzorrAgOeWGYYMeta || {},
    component: () => import("/tmp/build_98043470/pages/auth/sign-in/reset-password.vue")
  },
  {
    name: "map-place-nanoId-slug___en",
    path: "/map/place/:nanoId()/:slug?",
    meta: _91_91slug_93_93InScr5HuBUobOKMqng3X6TrAKw0kOZfXJJ92lQaloEwMeta || {},
    component: () => import("/tmp/build_98043470/pages/map/place/[nanoId]/[[slug]].vue")
  },
  {
    name: "auth-checkout-choose-product___en",
    path: "/auth/checkout/choose-product",
    meta: choose_45product3SOc2WQbxsa7t0t3iTIEQuCrilSBSd2THiDe3FjI6GYMeta || {},
    component: () => import("/tmp/build_98043470/pages/auth/checkout/choose-product.vue")
  },
  {
    name: "auth-register-create-profile___en",
    path: "/auth/register/create-profile",
    meta: create_45profileCtZGYQ6MprjLST6WCKOCrHqXg84_VGvF2xYDTpfEw2oMeta || {},
    component: () => import("/tmp/build_98043470/pages/auth/register/create-profile.vue")
  },
  {
    name: "dashboard-helper-tags-id___en",
    path: "/dashboard/helper/tags/:id?",
    meta: _91_91id_93_93lKgdP79N1C_45GIJ3qzJhUfeRsZ7IPq9poCKB56USWGXoMeta || {},
    component: () => import("/tmp/build_98043470/pages/dashboard/helper/tags/[[id]].vue")
  },
  {
    name: "map-list-nanoId-slug___en",
    path: "/map/list/:nanoId?/:slug?",
    meta: _91_91slug_93_93I5HpRbcE3XweAkvqOfBnmbrnX2TAA2p4c4gFbKBDimMMeta || {},
    component: () => import("/tmp/build_98043470/pages/map/list/[[nanoId]]/[[slug]].vue")
  },
  {
    name: "auth-checkout-success-quo-pro___en",
    path: "/auth/checkout/success-quo-pro",
    meta: success_45quo_45pro7u1w7FaiWrM160kGpVpYe5om6qdajOwSAd3EC7BXqXAMeta || {},
    component: () => import("/tmp/build_98043470/pages/auth/checkout/success-quo-pro.vue")
  },
  {
    name: "auth-invite-share-pro-success___en",
    path: "/auth/invite/share-pro-success",
    meta: share_45pro_45successwI5n7Mu_45qAgKTK6BvDNYEyK1N_6Mv3iTyxYwQXXyE9cMeta || {},
    component: () => import("/tmp/build_98043470/pages/auth/invite/share-pro-success.vue")
  },
  {
    name: "auth-sign-in-send-password-link___en",
    path: "/auth/sign-in/send-password-link",
    meta: send_45password_45linkOUB89OPdZX1H9rIIM5Ma0wA6Bs9I9BBPGIEdbTSY5PwMeta || {},
    component: () => import("/tmp/build_98043470/pages/auth/sign-in/send-password-link.vue")
  },
  {
    name: "dashboard-helper-countries___en",
    path: "/dashboard/helper/countries",
    meta: indexMuZmlbyqrBjLzkWW0XBFe6EeOYWShmFgwtUfJtHArEEMeta || {},
    component: () => import("/tmp/build_98043470/pages/dashboard/helper/countries/index.vue")
  },
  {
    name: "map-highlight-highlightUrlName___en",
    path: "/map/highlight/:highlightUrlName()",
    meta: _91highlightUrlName_93TVPIxojZrOdreAsqvAJx9DA03xouUtRt4jSC254g9wsMeta || {},
    component: () => import("/tmp/build_98043470/pages/map/highlight/[highlightUrlName].vue")
  },
  {
    name: "map-guide-nanoId-title-key___en",
    path: "/map/guide/:nanoId()/:title()/:key?",
    meta: indexkUxs7XHHYgTZHDAJTcyXWk_45hgMFgIhyEgOJkHC0Ge78Meta || {},
    component: () => import("/tmp/build_98043470/pages/map/guide/[nanoId]/[title]/[[key]]/index.vue")
  }
]